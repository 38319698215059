module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-7KZ7V3QTSP","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"enableWebVitalsTracking":true,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7KZ7V3QTSP"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://www.fbicgroup.com/internal-site/graphql","schema":{"timeout":600000,"requestConcurrency":150,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"previewRequestConcurrency":5},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FUNG BUSINESS INTELLIGENCE GROUP","short_name":"fbig","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/lifung-logo.png","icons":[{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"131894399e957cac8aa627808fc85144"},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true,"disableMinification":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
