import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "Roboto", "Arial", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Source Serif Pro", "Roboto", "Arial", "sans-serif"].join(
        ","
      ),
    },
    h2: {
      fontFamily: [
        "Source Serif Pro",
        "Open Sans",
        "Roboto",
        "Arial",
        "sans-serif",
      ].join(","),
    },
    h3: {
      fontFamily: [
        "Source Serif Pro",
        "Open Sans",
        "Roboto",
        "Arial",
        "sans-serif",
      ].join(","),
      fontWeight: 400,
    },
    h4: {
      fontFamily: [
        "Source Serif Pro",
        "Open Sans",
        "Roboto",
        "Arial",
        "sans-serif",
      ].join(","),
      fontWeight: 400,
    },
    h5: {
      fontFamily: [
        "Source Serif Pro",
        "Open Sans",
        "Roboto",
        "Arial",
        "sans-serif",
      ].join(","),
      fontWeight: 400,
    },
    h6: {
      fontFamily: [
        "Source Serif Pro",
        "Open Sans",
        "Roboto",
        "Arial",
        "sans-serif",
      ].join(","),
      fontWeight: 400,
    },
    body1: {
      fontFamily: ["Open Sans", "Roboto", "Arial", "sans-serif"].join(","),
      fontSize: "1.25rem",
    },
    body2: {
      fontFamily: ["Open Sans", "Roboto", "Arial", "sans-serif"].join(","),
      fontSize: "1.125rem",
    },
    button: {
      fontFamily: ["Open Sans", "Roboto", "Arial", "sans-serif"].join(","),
      textTransform: "none",
      fontSize: "1.125rem",
    },
  },
  palette: {
    primary: {
      // dark grey
      main: "#333333",
    },
    secondary: {
      // grey
      main: "#626465",
    },
    error: {
      // red
      main: "#CC202F",
    },
    info: {
      // light grey
      main: "#F2F2F2",
    },
    success: {
      // green
      main: "#1EB473",
    },
    warning: {
      // blue
      main: "#0B73B8",
    },
    text: {
      // dark grey
      primary: "#333333",
      // grey
      secondary: "#626465",
    },
    third: {
      main: "#F5F5F5",
    },
  },
});

// export default responsiveFontSizes(theme);
export default theme;
