// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-book-content-page-js": () => import("./../../../src/templates/book-content-page.js" /* webpackChunkName: "component---src-templates-book-content-page-js" */),
  "component---src-templates-book-page-js": () => import("./../../../src/templates/book-page.js" /* webpackChunkName: "component---src-templates-book-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-media-page-js": () => import("./../../../src/templates/media-page.js" /* webpackChunkName: "component---src-templates-media-page-js" */),
  "component---src-templates-publication-details-page-js": () => import("./../../../src/templates/publication-details-page.js" /* webpackChunkName: "component---src-templates-publication-details-page-js" */),
  "component---src-templates-publication-page-js": () => import("./../../../src/templates/publication-page.js" /* webpackChunkName: "component---src-templates-publication-page-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/search-page.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-sitemap-page-js": () => import("./../../../src/templates/sitemap-page.js" /* webpackChunkName: "component---src-templates-sitemap-page-js" */),
  "component---src-templates-speech-page-js": () => import("./../../../src/templates/speech-page.js" /* webpackChunkName: "component---src-templates-speech-page-js" */),
  "component---src-templates-subscribe-page-js": () => import("./../../../src/templates/subscribe-page.js" /* webpackChunkName: "component---src-templates-subscribe-page-js" */)
}

